import React, { Component, useState } from 'react'
import Photo from '../Photo';
import Form from "react-bootstrap/Form";
import "bootstrap/dist/css/bootstrap.min.css";
import "../../styles/footer.scss";
import { Link, Trans, I18nextContext } from "gatsby-plugin-react-i18next";
import { graphql } from "gatsby";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";
import Modal from 'react-bootstrap/Modal';
import CheckCircleOutlineOutlinedIcon from "@material-ui/icons/CheckCircleOutlineOutlined";

const Footer = () => {

  const context = React.useContext(I18nextContext);
  const { t } = useTranslation();
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  return (

    <footer className="main_footer" lang={context.language}>
      <div className="container">
        <Helmet>
          {/* <script src="https://kit.fontawesome.com/a076d05399.js" crossorigin="anonymous"></script> */}
        </Helmet>
        <div className="row">
          <div className="col-sm-12 horizontal_line"></div>
          <div className="col-md-2 col-6">

            <h4 lang={context.language}><Trans>COMPANY</Trans></h4>

            <ul lang={context.language}>
              <li lang={context.language}>
                <Link to="/about-us/"><Trans>About Us</Trans></Link>
              </li>
              <li lang={context.language}>
                <Link to="/contact-us/"><Trans>Contact Us</Trans></Link>
              </li>
            </ul>
          </div>
          <div className="col-md-2  col-6">
            <h4 lang={context.language}><Trans>RESOURCES</Trans></h4>
            <ul lang={context.language}>
              <li lang={context.language}>
                <Link to="/blog/"><Trans>Blogs</Trans></Link>
              </li>

              <li lang={context.language}>
                <Link to="/"><Trans>24x7 Help Desk</Trans></Link>
              </li>
            </ul>
          </div>
          <div className="col-md-3  col-6">
            <h4 lang={context.language}><Trans>OUR PRODUCTS</Trans></h4>
            <ul lang={context.language}>
              <li lang={context.language}>
                <Link to='/product/' language={context.language}><Trans>Freight Forwarding</Trans></Link>
              </li>
              <li lang={context.language}>
                <Link to="/product/warehouse-management-software/"><Trans>3PL & Warehousing</Trans></Link>
              </li>
              <li lang={context.language}>
                <Link to="/product/nvocc-software/"><Trans>NVOCC</Trans></Link>
              </li>
              <li lang={context.language}>
                <Link to="/features/"><Trans>Movers & Relocation</Trans></Link>
              </li>
            </ul>
          </div>
          <div className="col-md-3  col-6">
            <h4 lang={context.language}><Trans>FEATURES</Trans></h4>
            <ul lang={context.language}>
              <li lang={context.language}>
                <Link to="/features/#multilingual"><Trans>Multilingual</Trans></Link>
              </li>
              <li lang={context.language}>
                <Link to="/features/#multicurrency"><Trans>Multi-Currency</Trans></Link>
              </li>
              <li lang={context.language}>
                <Link to="features/#Transportation"><Trans>Transportation</Trans></Link>
              </li>
              <li lang={context.language}>
                <Link to="features/#customreport"><Trans>Custom Report Engine</Trans></Link>
              </li>
              <li lang={context.language}>
                <Link to="features/#dashboard"><Trans>Dashboard with KPI's & BI's</Trans></Link>
              </li>
              <li lang={context.language}>
                <Link to="features/#multipletab"><Trans>Multi-Tab Viewer</Trans></Link>
              </li>
              <li lang={context.language}>
                <Link to="features/#Hierarchy"><Trans>Hierarchy Maintenance</Trans></Link>
              </li>
              <li lang={context.language}>
                <Link to="features/#Alertengine"><Trans>Event Alert Engine</Trans></Link>
              </li>
            </ul>
          </div>
          <div className="col-md-2  col-6">
            <h4 lang={context.language}><Trans>SUPPORT</Trans></h4>
            <ul lang={context.language}>
              <li lang={context.language}>
                <Link to="/free-demo/"><Trans>Request Demo</Trans></Link>
              </li>

            </ul>
          </div>
        </div>

        <div className="partner" lang={context.language}>
          <div className="row">
            <div className="col-md-7">

              <div className="getupdated-form" lang={context.language}>

                <h4 lang={context.language}><Trans>Get Updated</Trans></h4>
                <p lang={context.language}><Trans>Subscribe to our newsletter</Trans></p>
              </div>

              <div className="form" lang={context.language}>
                <Form lang={context.language}>
                  <Form.Group controlId="formBasicEmail" lang={context.language}>
                    <div className="button_align" lang={context.language}>
                      {/* <div className="btn sub-btn" lang={context.language} onClick={handleShow}><Trans>SUBSCRIBE</Trans></div> */}
                      <div className="btn sub-btn" lang={context.language} onClick={handleShow}><Trans>SUBSCRIBE</Trans></div>
                      <Form.Control type="email" placeholder={t("Your Email ID")} />
                    </div>

                  </Form.Group>
                </Form>
              </div>
            </div>


            <div className="col-md-5">
              <div className="links align_link" lang={context.language}>
                <Link to="/free-trial/" className="trial" lang={context.language}
                  onClick={() => localStorage.removeItem("userinfo")}>
                  <Trans>GET A FREE TRIAL</Trans>
                </Link>
                <Link to="/free-demo" className="demo" lang={context.language}>
                  <Trans>REQUEST A DEMO</Trans>
                </Link>
              </div>
            </div>

            <div className="col-12">
              <div className="border-bott"></div>
            </div>

          </div>
        </div>

        <h1 className="faq-heading"><Trans>FAQ'S</Trans></h1>
        {/* <div class="container"> */}
        <div>
          <div class="row">
            <div class="col-sm-6" >
              <div className="faq-one">
                <h1 className="faq-page"><Trans>What is ocean freight forwarding software?</Trans></h1>
                <div className="faq-body">
                  <p><Trans>Ocean freight forwarding software automates and manages the process of an ocean freight forwarder promoting productivity and reducing costs.</Trans></p>

                </div>
              </div>
              <div className="faq-two">
                <h1 className="faq-page"><Trans>What is FCL and LCL?</Trans></h1>
                <div className="faq-body">
                  <p><Trans>FCL and LCL refer to the capacity or load a vessel can carry. FCL refers to when consignment from a single shipper would be enough to fill up the container. LCL is when only partial capacity of a single entire container is used by multiple shippers.</Trans></p>
                </div>
              </div>

              <div className="faq-three">
                <h1 className="faq-page"><Trans>Is Logipulse’s operations systems compatible with my company’s accounting software?</Trans></h1>
                <div className="faq-body">
                  <p><Trans>Logipulse is an end-to-end automated software. This means that all the processes that make up logistics and freight forwarding management like operations, finance, transport, warehouse management and inventory stock are all under one single platform.</Trans></p>
                </div>
              </div>


            </div>
            <div class="col-sm-6" >
              <div className="faq-four">
                <h1 className="faq-page"><Trans>Are updates for an ocean freight software in real time?</Trans></h1>
                <div className="faq-body">
                  <p><Trans>Logipulse tracks your ocean cargo at every state of its journey and sends regular updates via in app messages, WhatsApp, email and SMS alerts.</Trans></p>
                </div>
              </div>
              <div className="faq-five">
                <h1 className="faq-page"><Trans>I need to attach my documents as per each shipment or job, is this possible with Logipulse?</Trans></h1>
                <div className="faq-body">
                  <p><Trans>Yes, documents like proforma invoice, packing list, customs declaration forms, manifest reports, certs of origin etc can all be uploaded as attachments to the relevant job or shipment.</Trans></p>
                </div>
              </div>
              <div className="faq-six">
                <h1 className="faq-page"><Trans>Can ocean freight management software help in picking the best routes?</Trans></h1>
                <div className="faq-body">
                  <p><Trans>As technology is constantly evolving, optimising routes both traffic wise as well as frequently used routes is possible.</Trans></p>
                </div>
              </div>

            </div>
          </div>
        </div>

        {/* </div> */}

        <div className="col-12">
          <div className="border-bott"></div>
        </div>

        <div className="footer-bottom" lang={context.language}>
          <div className="row">
            <div className="col-md-2 powerdedby" lang={context.language}>
              <p className="power" lang={context.language}><Link to="/"><Trans>Powered by</Trans></Link></p>

              <Photo src="Stellosysyw-Logo.svg" alt="" className="logo" />
            </div>
            <div className="col-md-3  contactby">
              <div className="contact" lang={context.language}>
                <a href="tel:+91 9747469023" className="phone" lang={context.language}>
                  <Photo src="phone-24px.svg" alt="" className="" />
                  + 91 9747469023
                </a>

                <a href="mailto:sales@logipulse.com" className="email" lang={context.language} style={{ cursor: "pointer" }}>
                  <Photo src="email-24px.svg" lang={context.language} alt="" className="" />
                  <Trans>sales@logipulse.com</Trans>
                </a>
              </div>
            </div>
            <div className="col-md-5 contactedby" lang={context.language}>
              <div className="contacts-seconds" lang={context.language}>
                <Link to="/terms-and-conditions/" className="terms"><Trans>Terms of Use</Trans></Link>
                <Link to="/privacy-policy/" className="privacy" lang={context.language}><Trans>Privacy Policy</Trans></Link>
                <p className="copy_right" lang={context.language}><Trans>Copyright @ 2021 Stellosys</Trans><br></br><Trans>All rights Reserved</Trans></p>

              </div>
            </div>
            <div className="col-md-2 contacted-icons" lang={context.language}>
              <div className="facebook" lang={context.language}>
                <a href="https://www.facebook.com/Logipulse" target="_blank">
                  <Photo src="FB-SM Icon.svg" alt="facebook" className="" />
                </a>
              </div>
              <div className="instagram" lang={context.language}>
                <a href="https://www.instagram.com/logipulse" target="_blank">
                  <Photo src="IG-SM Icon.svg" alt="instagram" className="" />
                </a>
              </div>
              <div className="linkedin" lang={context.language}>
                <a href="https://www.linkedin.com/showcase/logipulse" target="_blank">
                  <Photo src="IN-SM Icon.svg" alt="linkedin" className="" />
                </a>
              </div>
              <div className="youtube" lang={context.language}>
                <a href="https://www.youtube.com/watch?v=R4ja3Y6ISOo" target="_blank">
                  <Photo src="YT-SM Icon.svg" alt="youtube" className="" />
                </a>
              </div>
            </div>


          </div>
        </div>
      </div>
      <div className="whatsapp_main" lang={context.language}>
        <div className="whattsapp" lang={context.language}>
          <a href="http://wa.me/+919747469023" target="_blank">
            <Photo src="whatsapp_black_24dp.svg" alt="whattsapp" className="" />
          </a>
        </div>

        <div className="chatpot" lang={context.language}><Photo src="question_answer_black_24dp.svg" alt="" className="" />
        </div>

      </div>
      <Modal show={show} onHide={handleClose} className="modaldialog">
        <Modal.Header closeButton>
          <Modal.Title>EMAIL SUBSCRIPTION</Modal.Title>
          <div className="line"></div>
        </Modal.Header>

        <Modal.Body>
          <CheckCircleOutlineOutlinedIcon className="material-stesting" />
          {/* <Photo
                             src="check_circle_black_24dp.svg"
                                className="material-stesting"
                                alt=""
                              />         */}
          <div className="email_content" placeholder="abcd@example.com">abcd@example.com</div>
          <div className="smodal-subcontent">Your subscription to our newsletter has been successful !</div>
          <div className="close-btn">ok</div>
          <div className="modal-subcontents"><span className="unsubscribe">Unsubscribe</span></div>

        </Modal.Body>

      </Modal>
    </footer>

  )
}

export default Footer
export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
